import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../layout';
import config from '../../data/SiteConfig';
import MarkdownSeo from '../components/SEO/MarkdownSEO';

export default function IndustryTemplate({ data }) {
  const industryNode = data.markdownRemark;
  const industry = industryNode.frontmatter;
  const url = '/industries';

  return (
    <Layout>
      <Helmet>
        <title>
          `${industry.title} | ${config.siteTitleShort}`}
        </title>
      </Helmet>
      <MarkdownSeo postNode={industryNode} url={url} />
      <div>
        <div className="legacy">
          <div dangerouslySetInnerHTML={{ __html: industryNode.html }} />
        </div>
      </div>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query IndustryBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
