import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../../../data/SiteConfig';
import { getSrc } from 'gatsby-plugin-image';

function MarkdownSeo({ postNode, url }) {
  let post = postNode.frontmatter;
  const image = getSrc(post.featuredImage) ? getSrc(post.featuredImage) : config.siteLogo;
  const defaultDescription =
    'We are a product development studio that combines UX, design and engineering expertise to build your product-market fit.';

  const schemaOrgJSONLD = [
    {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: config.siteUrl,
      name: config.siteTitle,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
      logo: config.siteLogo,
    },
  ];

  if (url === '/blog') {
    schemaOrgJSONLD.push({
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      url: `${config.siteUrl}${postNode.fields.slug}`,
      name: post.title,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
      headline: post.title,
      image: { '@type': 'ImageObject', url: image },
      author: post.author.name,
      publisher: {
        '@type': 'Organization',
        logo: config.siteLogo,
      },
      datePublished: post.date,
      description: post.excerpt,
    });
  } else if (url === '/careers') {
    schemaOrgJSONLD.push({
      '@context': 'https://schema.org/',
      '@type': 'JobPosting',
      title: post.title,
      description: postNode.html,
      identifier: {
        '@type': 'PropertyValue',
        name: 'SpiceFactory',
        value: '2031',
      },
      datePosted: post.date,
      employmentType: 'FULL_TIME',
      hiringOrganization: {
        '@type': 'Organization',
        name: 'SpiceFactory',
        sameAs: config.siteTitle,
        logo: config.siteLogo,
      },
      applicantLocationRequirements: [
        {
          '@type': 'Country',
          name: 'Serbia',
        },
        {
          '@type': 'Country',
          name: 'Portugal',
        },
        {
          '@type': 'Country',
          name: 'Bosnia and Herzegovina',
        },
      ],

      jobLocationType: 'TELECOMMUTE',
    });
  }

  return (
    <Helmet>
      {/* General tags */}
      <meta name="description" content={postNode.excerpt !== '' ? postNode.excerpt : defaultDescription} />
      <meta name="image" content={`${config.siteUrl}${image}`} />
      {/* Structured data */}
      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={`${config.siteUrl}${url}${postNode.fields.slug}`} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={post.title} />
      <meta property="og:description" content={postNode.excerpt !== '' ? postNode.excerpt : defaultDescription} />
      <meta property="og:image" content={`${config.siteUrl}${image}`} />
      <meta property="fb:app_id" content={config.siteFBAppID} />

      {/* Twitter Card tags */}
      <meta name="twitter:site" content="@SpiceFctry" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@SpiceFctry" />
      <meta name="twitter:title" content={post.title} />
      <meta name="twitter:description" content={postNode.excerpt !== '' ? postNode.excerpt : defaultDescription} />
      <meta name="twitter:image" content={`${config.siteUrl}${image}`} />
    </Helmet>
  );
}

export default MarkdownSeo;
